<template>
<!-- <LoadingOverlay v-if="isSkillInfo" /> -->
<div class="skill-info">
    <CCard class="mt-2 has-loading-overlay">
        <CCardBody>
            <CRow>
                <CCol class="md-9">
                    <h5 class="main-title pb-3">Skill Information</h5>
                </CCol>
                <CCol class="md-3 d-flex justify-content-end">
                  <CButton
                    v-if="!edit_skill_id"
                    class="btn-primary small mr-2"
                    style="height: 28px;"
                    @click="uploadSkillInfo"
                    v-c-tooltip="'Click to Add multiple records!'"
                    >
                     Add
                  </CButton>
                  <div v-if="edit_skill_id">
                    <CButton
                      class="btn-primary small mr-2"
                      style="height: 28px;"
                      @click="uploadSkillInfo"
                      v-c-tooltip="'Click to update the records!'"
                      >
                       Update
                    </CButton>
                    <button
                      name="cancel"
                      class="hand-icon action-button"
                      v-c-tooltip="'Cancel'"
                      @click="clearSelectedRecord()">
                        <CIcon name="cil-x-circle" style="margin-right:20px" />
                    </button>
                  </div>
                </CCol>
            </CRow>
            <ValidationObserver ref="skill" v-slot="{ handleSubmit }">
                <form id="skill" @submit.prevent="handleSubmit()">
                  <fieldset>
                    <CRow>
                        <CCol md="6">
                            <CRow class="d-flex align-items-center mb-3">
                          <label name="lbl_" class="required col-lg-12 col-md-12">Skills</label>
                          <div class="col-lg-12 col-md-12 col-sm-12">
                              <ValidationProvider rules="required" v-slot="{ errors }">
                              <Select
                                  name="skill_id"
                                  :value="profile.skill_id"
                                  @change="handleChangeSelect"
                                  :options="options && options['skill_id'] ? options['skill_id'] : []"
                                  :taggable="false"
                                  :multiple="false"
                                  :clearable="true"
                                  :error="errors[0]"
                              />
                              </ValidationProvider>
                          </div>
                        </CRow>
                        </CCol>
                        <CCol md="6">
                            <CRow class="d-flex align-items-center mb-3">
                                <label name="lbl_date_attained" class="col-lg-12 col-md-12">Attained Date</label>
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <DateInput
                                    name="date_attained"
                                    :value="profile.date_attained"
                                    @change="handleDatePickerChange"
                                    ref="dataAttained"
                                    :error="errors[0]" />
                                </div>
                            </CRow>
                        </CCol>
                    </CRow>
                    <!-- Hidden for Issue 663
                    <CRow>
                        <CCol md="6">
                            <CRow class="d-flex align-items-center mb-3">
                            <label name="lbl_procedures_performed" class="col-lg-12 col-md-12">Procedures Performed</label>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider rules="numeric" v-slot="{errors}">
                                <TextInput
                                    name="procedures_performed"
                                    :value="profile.procedures_performed"
                                    @change="handleInput"
                                    :error="errors[0]"
                                />
                            </ValidationProvider>
                            </div>
                        </CRow>
                      </CCol>
                      <CCol md="6">
                        <CRow class="d-flex align-items-center mb-3">
                          <label name="lbl_description" class="col-lg-12 col-md-12">Description</label>
                          <div class="col-lg-12 col-md-12 col-sm-12">
                             <TextInput
                                name="description"
                                :value="profile.description"
                                @change="handleInput"
                              />
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                        <CCol md="6">
                        <CRow class="d-flex align-items-center mb-3">
                          <label name="lbl_comments" class="col-lg-12 col-md-12">Comments</label>
                          <div class="col-lg-12 col-md-12 col-sm-12">
                             <TextInput
                                name="skillcomments"
                                :value="profile.skillcomments"
                                @change="handleInput"
                              />
                          </div>
                        </CRow>
                      </CCol>
                    </CRow> -->

                    <CRow class="ml-2 mr-2 d-flex align-items-center documentScrolling document-table" v-if="skills">
                      <table class="table">
                          <thead>
                          <tr>
                              <th class="w-25">Skill</th>
                              <th>Attained Date</th>
                              <!-- <th>Description</th> -->
                              <th>Edit</th>
                              <th>Delete</th>
                          </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(data, index) in skills" :key="index">
                              <td :class="{ 'w-25': true, [data.skill_name]: true }">
                                    {{ data.skill_name }}
                              </td>
                              <td>{{ data.date_attained }}</td>
                              <!-- <td>{{ data.description }}</td> -->
                              <td>
                                <button 
                                class="hand-icon action-button" v-if="data && data.candidate_skill_id" @click="editSkill(data)">
                                  <i class="fas fa-pencil-alt ml-3"></i>
                                </button>
                              </td>
                              <td>
                                <button
                                class="hand-icon action-button" v-if="data && data.candidate_skill_id" @click="deleteSkill(data)">
                                    <i class="fas fa-trash ml-3"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                      </table>
                    </CRow>

                  </fieldset>
                </form>
            </ValidationObserver>
        </CCardBody>
    </CCard>
    <Modal
        :modalTitle="deleteConfirmModal.modalTitle"
        :modalColor="deleteConfirmModal.modalColor"
        :modalContent="deleteConfirmModal.modalContent"
        :isShowPopup="deleteConfirmModal.isShowPopup"
        :buttons="deleteConfirmModal.buttons"
        :modalCallBack="deletemodalCallBack"
        :closeOnBackdrop="false"
     />
</div>

</template>

<script>
import ProfileSection from './ProfileSection';
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/reusable/Modal";
import { extend } from "vee-validate";
import {
  required,
  numeric
} from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
extend("numeric", { ...numeric, message: "Only numbers are allowed"});

export default {
  mixins: [ProfileSection],
  components:{
    Modal
  },
  data(){
    return{
      isDisabled: false,
      profile :{},
      payload :{},
      deleteConfirmModal:{
        modalColor :"danger",
        modalTitle :"NOTIFICATION",
        modalContent :"",
        isShowPopup :false,
        buttons :["Cancel","Confirm"],
      },
      candidate_skill_id : 0,
      errors:[],
      edit_skill_id : null,
      delete_skill: false,
    }
  },
  computed: {
     ...mapGetters([
      "uploaded",
      "getSkills",
      "candidateProfile"
    ]),
    options() {
      return {
        skill_id: this.skill || []
      }
    },
    skills() {
      return (
        this.getSkills &&
        this.getSkills.length &&
        this.getSkills.map(
          ({
            candidate_skill_id,
            candidate_uid,
            skill_id,
            skill_name,
            skill,
            date_attained,
            // procedures_performed,
            // description,
            // comments
          }) => {
            return {
              candidate_skill_id : candidate_skill_id !== null ? candidate_skill_id :"--",
              candidate_uid : candidate_uid !== null ? candidate_uid : "--",
              skill_id: skill_id !== null ? skill_id : "--",
              skill_name: skill !== null ? skill?.skill : "--",
              date_attained: date_attained !== null ? date_attained : "--",
              // procedures_performed: procedures_performed !== null ? procedures_performed : "--",
              // description: description !== null ? description : "--",
              // comments : comments !== null ? comments : "--"
            };
          }
        )
      );
    },
    
  },
  watch: {
    uploaded() {
       if (this.uploaded) {
        this.fetchSkills();
      }
    }
  },
  methods:{
    ...mapActions([
      "fetchSkills",
      "uploadSkill",
      "deleteCandidateSkills",
      "showToast",
      "updateSkill"
    ]),
    async uploadSkillInfo(){
       const isValid = await this.$refs.skill.validate();
       this.errors = Object.values(this.$refs.skill.errors).flat();
      if (!isValid) {
        let el = this.$el.getElementsByClassName("has-error")[0];
        el.scrollIntoView(true);
        window.scrollBy(0, -60);
        this.showToast({
          class: "bg-danger text-white",
          message: this.errors[0] || "please fill mandatory fields!"
        });
        return;
      } 
      else {
        if(this.edit_skill_id !== null){
          let _count = 0;
          let _index;
          for(var i=0 ; i<this.getSkills.length;i++){
            if(this.getSkills[i].skill_id === this.profile.skill_id.code){
              _index = i;
              _count = _count+1;
            }
          }
          if(_count >= 1 && this.getSkills[_index].candidate_skill_id !== this.edit_skill_id){
            this.showToast({
              class: "bg-danger text-white",
              message: "Skill Already Exists!!"
            });
          }
          else{
            this.saveSkillInfo();
          }
        }
        else{
          if((this.getSkills && this.getSkills.length) && (this.getSkills.findIndex(e => e.skill_id === this.profile.skill_id.code)) >=0 ){
            this.showToast({
              class: "bg-danger text-white",
              message: "Skill Already Exists!!"
            });
          }
          else {
            this.saveSkillInfo();
          }
        }
      }
    },
    clearFields(){
      this.$nextTick(() => {
        this.$refs.skill.errors.clear;
        this.$refs.skill.reset();
        
      });
    },
    deleteSkill(data){
      this.delete_skill = true;
      this.editSkill(data);
      this.deleteConfirmModal.modalContent = `Do you want to delete this Skill - ${data.skill_name} ?`
      this.candidate_skill_id = data.candidate_skill_id;
      this.deleteConfirmModal.isShowPopup = true;
    },
    deletemodalCallBack(action){
      if(action === "Confirm"){
        this.deleteCandidateSkills(this.candidate_skill_id);
      }
      this.delete_skill = false;
      this.candidate_skill_id = "";
      this.edit_skill_id = null;
      this.profile = {};
      this.$refs.dataAttained.resetDate();
      this.clearFields();
      this.deleteConfirmModal.isShowPopup = false;
    },
    editSkill(data){
      this.profile = {
        ...this.profile,
            candidate_uid : data.candidate_uid,
            skill_id : {
              code: data.skill_id,
              label: data.skill_name
            },
            date_attained : data.date_attained,
            // procedures_performed : data.procedures_performed !== "--" ? data.procedures_performed : null,
            // description : data.description !== "--" ? data.description : null,
            // skillcomments : data.comments !== "--" ? data.comments : null
        
      }
      !this.delete_skill ? this.edit_skill_id = data.candidate_skill_id : null;
    },
    saveSkillInfo(){
      this.payload ={
              candidate_uid : this.candidateProfile.candidate_uid,
              skill_id : this.profile.skill_id.code !== null ? this.profile.skill_id.code : null,
              date_attained : this.profile.date_attained !== null ? this.profile.date_attained : null,
              // procedures_performed : this.profile?.procedures_performed,
              // description : this.profile?.description,
              // comments :this.profile?.skillcomments
            }
            let appendAction;
            if(this.payload && this.edit_skill_id === null ){
              
              appendAction = this.uploadSkill(this.payload);
            }
            else{
              this.payload ={
                ...this.payload,
                deleted: false,
                candidate_skill_id : this.edit_skill_id
              }
              appendAction = this.updateSkill(this.payload);
              this.edit_skill_id = null;
            }
            Promise.all([appendAction]).then(res => {
              this.profile = {};
              this.$refs.dataAttained.resetDate();
              this.clearFields();
            })
    },
    clearSelectedRecord() {
      this.edit_skill_id = null;
      this.profile = {};
      this.$refs.dataAttained.resetDate();
      this.clearFields();
    },
  },
};
</script>
