<template>
  <div class="candidate-profile">
    <div class="splInterestInfo" v-if="!isStudentDentist">
      <div v-if="cardError"></div>
      <div class="pb-1" :class="cardHasError ? 'card-error-msg' : ''">
        {{ cardHasError }}
      </div>
      <CCard class="mt-2" :class="cardHasError ? 'card-has-error' : ''">
        <CCardBody>
          <CRow>
            <div class="col-md-12">
              <div class="d-flex align-items-center justify-content-between">
                <div class="mt-2 mr-2">
                  <h5 class="pb-3 main-title">
                    {{
                      isAHP
                        ? `Other Areas of Expertise`
                        : isNonClinicAdmin
                          ? `Areas of Expertise`
                          : `Special Interest Information`
                    }}<span class="h6">
                      [{{ splInterests.length || "0" }} record{{
                        splInterests.length > 1 ? "s" : ""
                      }}]</span
                    >
                  </h5>
                </div>
              </div>
              <ValidationObserver ref="splInterest" v-slot="{ handleSubmit }">
                <form
                  id="splInterest"
                  name="splInterest"
                  @submit.prevent="handleSubmit()"
                >
                  <fieldset :disabled="isDisabled">
                    <CRow>
                      <CCol md="10">
                        <CRow>
                          <CCol md="6">
                            <CRow class="row mb-3">
                              <label
                                name="lbl_special_interest"
                                class="col-lg-12 col-md-12"
                                >{{
                                  isAHP
                                    ? `Other Areas of Expertise`
                                    : isNonClinicAdmin
                                      ? `Areas of Expertise`
                                      : `Special as Interest`
                                }}</label
                              >
                              <div class="col-lg-12 col-md-12 col-sm-12">
                                <ValidationProvider
                                  rules="singleRequired"
                                  v-slot="{ errors }"
                                >
                                  <Select
                                    name="special_interest_id"
                                    :value="profile.special_interest_id"
                                    @change="handleChangeSelect"
                                    :options="
                                      options && options['specialInterest']
                                        ? options['specialInterest']
                                        : []
                                    "
                                    :taggable="false"
                                    :multiple="false"
                                    :clearable="false"
                                    :disabled="isDisabled"
                                    :error="errors[0]"
                                  />
                                </ValidationProvider>
                              </div>
                            </CRow>
                          </CCol>
                          <CCol md="6">
                            <CRow class="row mb-3" v-if="isSplOther">
                              <label
                                name="lbl_special_interest_other"
                                class="col-lg-12 col-md-12"
                                >Name Special Interest</label
                              >
                              <div class="col-lg-12 col-md-12 col-sm-12">
                                <TextInput
                                  name="other_special_interest"
                                  :value="profile.other_special_interest"
                                  @change="handleInput"
                                />
                              </div>
                            </CRow>
                          </CCol>
                        </CRow>
                      </CCol>
                      <CCol md="2">
                        <CRow
                          class="justify-content-end"
                          v-if="
                            edit_speciality_id ||
                            getMultiFormEdited['splInterest']
                          "
                        >
                          <label class="col-lg-12 col-md-12" style="color: #fff"
                            >Action</label
                          >

                          <div class="justify-content-end">
                            <CButton
                              class="btn-primary small mr-1"
                              style="height: 28px"
                              v-if="
                                !edit_speciality_id &&
                                getMultiFormEdited['splInterest']
                              "
                              @click="uploadCandidateSpecialInterest"
                              v-c-tooltip="
                                'Click to Add multiple records!'
                              "
                              >Add</CButton
                            >
                            <CButton
                              class="btn-primary small mr-1"
                              style="height: 28px"
                              @click="uploadCandidateSpecialInterest"
                              v-if="edit_speciality_id"
                              v-c-tooltip="
                                'Click to update the record!'
                              "
                              >Update</CButton
                            >
                            <button
                              name="cancel"
                              class="hand-icon action-button"
                              v-c-tooltip="'Cancel'"
                              @click="resetSplInterest(), clearFields()"
                            >
                              <CIcon
                                name="cil-x-circle"
                                style="margin-right: 20px"
                              />
                            </button>
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <div v-if="splInterests" class="multi-records mt-2">
                      <div
                        class="top-border d-flex ml-1"
                        v-for="(data, index) in splInterests"
                        :key="index"
                      >
                        <div class="ml-2 mt-1 record">
                          <div class="widget-content-actions hide-large-screen">
                            <CButton
                              class="icon icon-edit mr-2"
                              v-if="data && data.candidate_speciality_id"
                              @click="editSplInterest(data)"
                            >
                            </CButton>
                            <CButton
                              class="icon mr-1"
                              v-if="data && data.candidate_speciality_id"
                              @click="deleteInfo(data)"
                            >
                              <i class="fas fa-trash-alt" />
                            </CButton>
                          </div>
                          <CRow class="ml-1">
                            <div class="table col col-6">
                              <p class="head">
                                {{
                                  isAHP
                                    ? `Other Areas of Expertise`
                                    : `Special Interest`
                                }}
                                {{
                                  ["Other", "Other - not listed"].includes(
                                    data.special_interest_name
                                  )
                                    ? `(Other)`
                                    : ``
                                }}
                              </p>
                              <p class="data">
                                {{
                                  ["Other", "Other - not listed"].includes(
                                    data.special_interest_name
                                  )
                                    ? data.other_special_interest
                                    : data.special_interest_name
                                }}
                              </p>
                            </div>
                          </CRow>
                        </div>
                        <!-- <div
                          class="widget-content-actions d-flex flex-column justify-content-center"
                        >
                          <CButton
                            class="icon"
                            v-if="data && data.candidate_speciality_id"
                            @click="deleteInfo(data)"
                          >
                            <i class="fas fa-trash-alt" />
                          </CButton>
                        </div> -->
                        <div class="hide-small-screen">
                          <div
                            class="widget-content-actions d-flex flex-column justify-content-center"
                          >
                            <CButton
                              class="icon icon-edit mb-2"
                              v-if="data && data.candidate_speciality_id"
                              @click="editSplInterest(data)"
                            >
                            </CButton>
                            <CButton
                              class="icon"
                              v-if="data && data.candidate_speciality_id"
                              @click="deleteInfo(data)"
                            >
                              <i class="fas fa-trash-alt" />
                            </CButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </ValidationObserver>
            </div>
          </CRow>
        </CCardBody>
      </CCard>
      <Modal
        :modalTitle="deleteConfirmModal.modalTitle"
        :modalColor="deleteConfirmModal.modalColor"
        :modalContent="deleteConfirmModal.modalContent"
        :isShowPopup="deleteConfirmModal.isShowPopup"
        :buttons="deleteConfirmModal.buttons"
        :modalCallBack="deletemodalCallBack"
        :closeOnBackdrop="false"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import ProfileSection from "./ProfileSection";
import Modal from "@/components/reusable/Modal";
import { extend, validate } from "vee-validate";
import { mapActions, mapGetters } from "vuex";
import { required } from "vee-validate/dist/rules";
import { isEmptyObjectCheck } from "../../helpers/helper";
extend("singleRequired", {
  ...required,
  message: "Please input atleast one of the fields!",
});
export default {
  name: "SpecialInterestInfoTFind",
  mixins: [ProfileSection],
  components: {
    Modal,
  },
  data() {
    return {
      isDisabled: false,
      payload: {},
      profile: {},
      SplInterestPayload: {},
      splinterestUpload: false,
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      splInterest: {},
      errors: [],
      delete_splInterest: false,
      isSplInterestOther: false,
      cardHasError: "",
      edit_speciality_id: null,
    };
  },
  computed: {
    ...mapGetters([
      "uploaded",
      "getCandidateSpecialInterest",
      "candidateProfile",
      "specialInterestFilterBySpeciality",
      "specialInterestFilterAHP",
    ]),
    options() {
      return {
        specialInterest: this.isAHP ? this.splInterestOptions : this.getFilteredSpecialInterest || [],
        boolean: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
      };
    },
    splInterestOptions() {
      let opt =
        this.specialInterestFilterBySpeciality({
          speciality: this.candidateProfile.speciality_id,
        }) || [];
      if (this.isAHP) {
        let opt_ahp =
          this.specialInterestFilterAHP({
            candidate_type_id: this.candidateProfile?.candidate_type_id,
            speciality: null,
          }) || [];
        opt = [...opt, ...opt_ahp];
        opt=_.uniqBy(opt, 'code')
      }
      if (!opt.length) opt.push({ label: "Other", code: "Other" });
      return opt;
    },
    splInterests() {
      if (
        Object.keys(this.candidateProfile).length &&
        isEmptyObjectCheck(this.profile)
      ) {
        const profileData = this.candidateProfile;
        this.profile = {
          customer_uid: profileData.customer_uid,
          candidate_uid: profileData.candidate_uid,
          speciality_id: profileData.speciality,
          sub_speciality_id: profileData.sub_speciality_id,
        };
      }
      return (
        this.getCandidateSpecialInterest &&
        this.getCandidateSpecialInterest.length &&
        this.getCandidateSpecialInterest
          .filter(
            (val) => val.special_interest_id || val.other_special_interest
          )
          .map(
            ({
              candidate_speciality_id,
              special_interest,
              other_special_interest,
            }) => {
              return {
                candidate_speciality_id:
                  candidate_speciality_id !== null
                    ? candidate_speciality_id
                    : "--",
                special_interest_id:
                  special_interest !== null
                    ? special_interest.special_interest_id
                    : "--",
                special_interest_name:
                  special_interest !== null
                    ? special_interest.special_interest
                    : "Other - not listed",
                other_special_interest:
                  other_special_interest !== null
                    ? other_special_interest
                    : "--",
              };
            }
          )
      );
    },
    cardError() {
      if (
        this.getMultiFormEdited.showError &&
        this.getMultiFormEdited.splInterest
      ) {
        this.cardHasError = "Please Save or Delete";
        let el = this.$el.getElementsByClassName("splInterestInfo")[0];
        el.scrollIntoView(true);
        el.scrollIntoView({ block: "center" });
        window.scrollBy(0, -200);
        return true;
      }
      return false;
    },
    isNurse() {
      return this.candidateProfile?.candidate_type_id === 2 || false;
    },
    isAHP() {
      return this.candidateProfile?.candidate_type_id === 3 || false;
    },
    isNonClinicAdmin() {
      return this.candidateProfile?.candidate_type_id === 6 || false;
    },
    isSplOther() {
      return (
        (this.profile?.special_interest_id?.code &&
          ["Other", "Other - not listed"].includes(
            this.profile?.special_interest_id?.label
          )) ||
        false
      );
    },
    isDentist() {
      return this.candidateProfile?.candidate_type_id === 4 || false;
    },
    isStudent() {
      return this.candidateProfile?.is_student;
    },
    isStudentDentist() {
      return this.isDentist && this.isStudent;
    },
  },
  watch: {
    uploaded() {
      if (this.uploaded) {
        if (this.splinterestUpload) {
          this.fetchspecialInterest();
          this.splinterestUpload = false;
        }
      }
    },
  },
  methods: {
    ...mapActions([
      "deleteCandidateSpecialInterest",
      "fetchspecialInterest",
      "uploadSpecialInterest",
      "updateSplInterest",
    ]),
    async uploadCandidateSpecialInterest() {
      this.tooltipHide();
      const { candidate_uid } = this.profile;
      const isValid = await this.$refs.splInterest.validate();
      this.errors = Object.values(this.$refs.splInterest.errors).flat();
      if (!isValid) {
        let el = this.$el.getElementsByClassName("has-error")[0];
        el.scrollIntoView(true);
        window.scrollBy(0, -60);
        this.cardHasError =
          this.errors[0] || "Please input atleast one of the fields!";
        return;
      } else if (
        this.profile.special_interest_id.code == "Other" &&
        !this.profile.other_special_interest
      ) {
        this.cardHasError = "Please enter special interest other field!";
        return;
      } else {
        if (
          this.profile.special_interest_id.label != "Other - not listed"&&
          this.getCandidateSpecialInterest &&
          this.getCandidateSpecialInterest.length &&
          this.profile.special_interest_id !== null &&
          this.profile.special_interest_id !== undefined &&
          this.getCandidateSpecialInterest.findIndex(
            (e) =>
              e.special_interest_id === this.profile.special_interest_id.code &&
              e.candidate_speciality_id !== this.edit_speciality_id
          ) >= 0
        ) {
          this.cardHasError = "Special Interest Already Exists!";
        } else {
          this.saveSplInterest();
        }
      }
    },
    saveSplInterest() {
      this.splinterestUpload = true;
      this.SplInterestPayload = {
        speciality_id: this.candidateProfile.speciality_id
          ? this.candidateProfile.speciality_id
          : null,
        special_interest_id: this.profile.special_interest_id
          ? this.profile?.special_interest_id?.code == "Other"
            ? null
            : this.profile?.special_interest_id?.code
          : null,
        other_special_interest: this.profile.other_special_interest
          ? ["Other", "Other - not listed"].includes(
              this.profile?.special_interest_id?.label
            )
            ? this.profile?.other_special_interest
            : null
          : null,
      };

      let appendAction;
      if (this.SplInterestPayload) {
        if (this.edit_speciality_id === null)
          appendAction = this.uploadSpecialInterest(this.SplInterestPayload);
        else {
          this.SplInterestPayload = {
            ...this.SplInterestPayload,
            candidate_speciality_id: this.edit_speciality_id,
          };
          appendAction = this.updateSplInterest(this.SplInterestPayload);
        }
      }
      Promise.all([appendAction]).then((res) => {
        this.resetSplInterest();
        this.clearFields();
      });
    },
    resetSplInterest() {
      this.multiFormEdited({ splInterest: false });
      this.profile.special_interest_id = null;
      this.profile.other_special_interest = null;
      this.delete_splInterest = false;
      this.isSplInterestOther = false;
      this.edit_speciality_id = null;
    },
    clearFields() {
      this.$nextTick(() => {
        this.$refs.splInterest.errors.clear;
        this.$refs.splInterest.reset();
      });
      this.tooltipHide();
      this.cardHasError = "";
      this.multiFormEdited({ showError: false });
    },
    editSplInterest(data) {
      let el = this.$el.getElementsByClassName("splInterestInfo")[0];
      el.scrollIntoView(true);
      window.scrollBy(0, -200);
      this.profile = {
        special_interest_id:
          data?.special_interest_id !== "--"
            ? {
                code: data?.special_interest_id,
                label: data?.special_interest_name,
              }
            : {
                code: "Other",
                label: "Other - not listed",
              },
        other_special_interest:
          data?.other_special_interest !== "--"
            ? data?.other_special_interest
            : null,
      };
      this.edit_speciality_id = data?.candidate_speciality_id;
    },
    deleteInfo(data) {
      this.delete_splInterest = true;
      this.deleteConfirmModal.modalContent = `Do you want to delete this SpecialInterest Info ? - ${
        data.special_interest_name != "Other"
          ? data.special_interest_name
          : data.other_special_interest
      }`;
      this.candidate_speciality_id = data.candidate_speciality_id;
      this.deleteConfirmModal.isShowPopup = true;
    },
    deletemodalCallBack(action) {
      if (action === "Confirm") {
        this.deleteCandidateSpecialInterest(this.candidate_speciality_id);
      }
      this.resetSplInterest();
      this.clearFields();
      this.deleteConfirmModal.isShowPopup = false;
    },
    tooltipHide() {
      setTimeout(() => {
        let x = document.querySelectorAll(".tooltip");
        for (var i = 0; i < x.length; i++) {
          x[i].style.visibility = "hidden";
        }
      }, 100);
    },
  },
  filters: {
    yesOrNo(data) {
      if (data != null) return data ? "Yes" : "No";
      return "--";
    },
  },
  mounted() {
    if (!this.isAHP) {
      this.fetchSpecialInterestByParams({
        speciality_id: this.candidateProfile?.speciality_id,
        candidate_type_id: this.candidateProfile?.candidate_type_id,
      });
    }
  }
};
</script>
